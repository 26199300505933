<script setup lang="ts">
import Dialog from 'primevue/dialog'
import SelectButton from 'primevue/selectbutton'
import { ref } from 'vue'
import { Stack, Button, Message } from './ui'
import InputText from 'primevue/inputtext'
import InputGroup from 'primevue/inputgroup'
import { useClientPromise } from '~/utils'

const findUserDialogVisible = defineModel<boolean>('visible', { default: false })
const selectedOption = ref('email')
const searchValue = ref('')

const options = [
  {
    label: 'Email',
    value: 'email'
  },
  {
    label: 'User Id',
    value: 'username'
  }
  // {
  //   label: 'Group ID',
  //   value: 'group_id'
  // },
  // {
  //   label: 'Link Code',
  //   value: 'link_code'
  // }
]

const errorMessage = ref('')
const userData = ref<Record<string, any>>()
const loading = ref(false)

const search = async () => {
  errorMessage.value = ''
  loading.value = true
  const username = selectedOption.value === 'username' ? searchValue.value : ''
  const email = selectedOption.value === 'email' ? searchValue.value : ''
  const [err, data] = await useClientPromise('/api/v1/admin/account-stats-search',
    {
      query: { username, email, groupId: '' }
    }
  )
  loading.value = false
  if (err) {
    errorMessage.value = err.message
    return
  }
  userData.value = data
}

const login = async () => {
  errorMessage.value = ''
  loading.value = true
  const [err] = await useClientPromise('/v3.5/admin/impersonate',
    {
      query: {
        username: userData.value!.username
        /** @note not working yet */
        // jsconfig: 'epcn'
      }
    }
  )
  loading.value = false
  const handleError = (err: Error) => {
    if (err) {
      errorMessage.value = err.message
    }
  }
  if (err) {
    return handleError(err)
  }
  navigateTo('/home', { external: true })
}
</script>

<template>
  <Dialog
    :draggable="false"
    modal
    header="Find a user"
    v-model:visible="findUserDialogVisible"
    blockScroll
  >
    <Stack v-if="!userData">
      <Message v-if="errorMessage" severity="error" :message="errorMessage" />
      <SelectButton
        v-model="selectedOption"
        :options
        optionLabel="label"
        optionValue="value"
      />
      <form @submit.prevent="search">
        <InputGroup>
          <InputText v-model="searchValue" />
          <Button @click="search" :loading :disabled="!searchValue">
            Search
          </Button>
        </InputGroup>
      </form>
    </Stack>
    <Stack v-else>
      <div>
        <b>User id</b><br />
        {{ userData.username }}
      </div>
      <div>
        <b>Email</b><br />
        {{ userData.email }}
      </div>
      <Button @click="login" :loading>
        Log in as <b>{{ userData.email }}</b>
      </Button>
    </Stack>
  </Dialog>
</template>
